import React, { useState } from "react";
import { withFormik, Formik } from "formik";
import * as Yup from "yup";
// import BarcodeScannerComponent from "react-webcam-barcode-scanner";
import BarcodeReader from "react-barcode-reader";

import { Modal, useModal } from "../../components/anti/modal/modal";

import { Container, Section } from "../../components/anti/grid/grid";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    passcode: Yup.string().required("Passcode / Name is required!"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required!"),
    telephonenumber: Yup.string()
      .min(7, "Too Short")
      .max(12, "Too Long")
      .required("Telephone Number is required!"),
    phonenumber: Yup.string()
      .min(10, "Too Short")
      .max(12, "Too Long")
      .required("Phone Number is required!"),
    topics: Yup.string().required("Please choose Topics"),
  }),
  mapPropsToValues: (props) => ({
    email: "",
    topics: "",
  }),
  handleSubmit(values) {},
});

const MyForm = (props) => {
  // Modal
  // const { isShowing, toggle } = useModal();

  const [namaGuest, setNamaGuest] = useState("Amy Adams");
  const [totalGuest, setTotalGuest] = useState("2");
  const [numTableGuest, setnumTableGuest] = useState("010");
  

  const [result, setResult] = useState("");
  const [error, setError] = useState(false);

  const handleScan = (data) => {
    setResult(data);
    console.log(data);
    // toggle();
  };

  const handleError = (err) => {
    console.error(err);
  };

  return (
    <div>
      <Section className="login-page">
        <Container className="mw-sm">
          <div className="login-box p-box text-center">
            <h2 className="text-center">Scan QR</h2>
            <p>
              Minta tamu untuk menunjukkan passcode yang telah dikirimkan ke
              nomor whatsapp untuk melakukan check-in aas
            </p>
            <BarcodeReader onError={handleError} onScan={handleScan} />
            <p>{result}</p>
          </div>
        </Container>
      </Section>

      {/* <Modal isShowing={isShowing} hide={toggle}>
        <div className="modal-checkin">
          <h2 className="h4">Check-in {namaGuest} Berhasil</h2>
          <p className="notes">
            {totalGuest} orang tamu, checked in jam 18:45.
            <br />
            Berikan nomor meja dan amplop hadiah kepada tamu.
          </p>
          <div className="p-box bg-success">
            <h2 className="h6">No. Meja </h2>
            <h2 className="h2">001</h2>
          </div>
          <div className="row">
            <div className="col">
              <button
                className="btn btn-outline-primary btn-block"
                onClick={toggle}
              >
                Print
              </button>
            </div>
            <div className="col">
              <button className="btn btn-primary btn-block" onClick={toggle}>
                Selesai
              </button>
            </div>
          </div>
        </div>
      </Modal> */}
    </div>
  );
};

const MyEnhancedForm = formikEnhancer(MyForm);

const Barcode = () => {
  return (
    <div>
      <MyEnhancedForm />
    </div>
  );
};

export default Barcode;
